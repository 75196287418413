import axios from "axios";
import { useEffect, useState } from "react";

export const useGetClusterData = ({
  showGlobbers,
}: {
  showGlobbers?: boolean;
}) => {
  const [globbers, setGlobbers] = useState<any | null>(null);
  const [rawData, setRawData] = useState([]);

  const getAllGlobbers = async () => {
    try {
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_SHAGA_BACKEND_API + "/clusters",
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          const { data } = response.data;

          setRawData(data);

          let finalData = {
            type: "FeatureCollection",
            crs: {
              type: "name",
              properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
            },
            features: [],
          };

          data.forEach((element: any[]) => {
            finalData.features.push({
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [element[3], element[2]],
              },
              properties: {
                city: element[0],
                count: element[1],
              },
            });
          });

          setGlobbers(finalData);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllGlobbers();
    // if (showGlobbers) {
    //   getAllGlobbers();
    // } else {
    //   setGlobbers(null);
    // }
  }, [showGlobbers]);

  return { globbers, setGlobbers, rawData };
};
