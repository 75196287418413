import * as React from "react";

function GradientPanel(props) {
  const { maxvalue } = props;
  const numberWithApostrophes = (number) => {
    return number.toLocaleString().replace(/,/g, "'"); // Replace commas with apostrophes
  };
  return (
    <div className="gradient-panel">
      <div className="gradient-div">
        <span className="top-span">
          Max:
          {maxvalue < 1
            ? numberWithApostrophes(Math.floor(maxvalue * 100) / 100)
            : maxvalue < 100
            ? numberWithApostrophes(maxvalue)
            : maxvalue < 10000
            ? numberWithApostrophes(Math.floor(maxvalue / 100) * 100)
            : maxvalue < 100000
            ? numberWithApostrophes(Math.floor(maxvalue / 1000) * 1000)
            : numberWithApostrophes(Math.floor(maxvalue / 100000) * 100000)}
        </span>
        <span className="bottom-span">Min:0</span>
      </div>
    </div>
  );
}

export default React.memo(GradientPanel);
