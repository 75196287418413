import React from "react";
import ReactDOM from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const providerConfig = {
  domain: process.env.REACT_APP_OAUTH_DOMAIN,
  clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
  authorizationParams: {
    redirect_uri: window.location.origin,
    // ...(process.env.REACT_APP_OAUTH_AUDIENCE
    //   ? { audience: process.env.REACT_APP_OAUTH_AUDIENCE }
    //   : null),
    // scope: "openid read:current_user",
  },
};

root.render(
  <React.StrictMode>
    <Auth0Provider {...providerConfig}>
      <App />
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
