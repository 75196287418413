import { Popup } from "react-map-gl";

export const MarketPopup = ({ markerInfo, setMarkerInfo }: any) => {
  return (
    <Popup
      anchor="top"
      longitude={Number(markerInfo.longitude)}
      latitude={Number(markerInfo.latitude)}
      onClose={() => setMarkerInfo(null)}
    >
      <div>{markerInfo.city}</div>
      <img width="100%" src={markerInfo.img} />
    </Popup>
  );
};
