import * as React from "react";

function SelectPanel(props) {
  const {
    filter,
    onChangeFilter,
    setShowGlobbers,
    showGlobbers,
    showNodes,
    setShowNodes,
    count,
    nodeCount,
    setShowCampaigns,
  } = props;
  const handleSelectChange = (event) => {
    onChangeFilter(event.target.value);
  };

  return (
    <div className="select-panel" style={{ height: "300px" }}>
      <h3>Filter View</h3>
      <hr />
      <div className="input">
        <select value={filter} onChange={handleSelectChange}>
          <option value="Mobile Young Gamers">Young Metropolitan Gamers</option>
          <option value="Broadband (Mbps)">Broadband (Mbps)</option>
          <option value="Population2023">Population2023</option>
          <option value="Avg Electricity $/kWh">Avg Electricity $/kWh</option>
          <option value="Broadband-Pop Ratio">Broadband-Pop Ratio</option>
          <option value="Energy-Pop Ratio">Energy-Pop Ratio</option>
          <option value="Score-Elec/Broadband">Score-Elec/Broadband</option>
          <option value="Score-Population">Score-Population</option>
        </select>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <span>Show Globbers</span>
        <label className="switch">
          <input
            type="checkbox"
            checked={showGlobbers}
            onChange={() => setShowGlobbers((prev) => !prev)}
          />
          <span className="slider round"></span>
        </label>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <span>Show Nodes</span>
        <label className="switch">
          <input
            type="checkbox"
            checked={showNodes}
            onChange={() => setShowNodes((prev) => !prev)}
          />
          <span className="slider round"></span>
        </label>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <span>Selected Globbers: {count}</span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <span>Selected Nodes: {nodeCount}</span>
      </div>
      <button
        className="button-9"
        role="button"
        disabled={count > 0 || nodeCount > 0 ? false : true}
        onClick={() => setShowCampaigns((prev) => !prev)}
      >
        Send Campaign
      </button>
    </div>
  );
}

export default React.memo(SelectPanel);
