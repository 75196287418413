import { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import MapGL, { Source, Layer, Marker } from "react-map-gl";
import SelectPanel from "./select-panel";
import ControlPanel from "./control-panel";
import { generateDataLayer } from "./map-style";
import GradientPanel from "./gradient-panel";
import { filterPointsWithinPolygon } from "../../utils/helpers";
import { useGetClusterData } from "../../hooks/useGetClusterData";
import { circleStyle, heatmapStyle } from "./heathmap-style";
import DrawControl from "./draw-control";
import Pin from "./pin";
import { NODES } from "../../consts";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { Tooltip } from "./Tooltip";
import { MarketPopup } from "./MarkerPopup";
import { Campaigns } from "./Campaigns";

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN; // Set your mapbox token here
let maxvalue = 0;
export const Map = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const [mapLoaded, setMapLoaded] = useState(false);
  const [filter, setFilter] = useState("Population2023");
  const [allData, setAllData] = useState(null);
  const [hoverInfo, setHoverInfo] = useState(null);
  const [showGlobbers, setShowGlobbers] = useState(false);
  const [showNodes, setShowNodes] = useState(false);
  const [selectedGlobbers, setSelectedGlobbers] = useState(0);
  const [selectedNodes, setSelectedNodes] = useState(0);
  const [markerInfo, setMarkerInfo] = useState(null);
  const [showCampaigns, setShowCampaigns] = useState(false);
  const { globbers, rawData } = useGetClusterData({ showGlobbers });

  const [values, setValues] = useState({
    hoursAvailale: 16,
    utilizationRate: 65,
    creditUnitCost: 0.5,
    clientPerNode: 4,
    sTierDistribution: 10,
    hTierDistribution: 30,
    mTierDistribution: 60,
    sPcCost: 3500,
    hPcCost: 2000,
    mPcCost: 1200,
    sTaxRate: 15,
    hTaxRate: 10,
    mTaxRate: 5,
    sEnergyConsumption: 1,
    hEnergyConsumption: 0.7,
    mEnergyConsumption: 0.5,
    sCreditPerHourCost: 1.5,
    hCreditPerHourCost: 1,
    mCreditPerHourCost: 0.5,
    sYearIncentive: 0,
    hYearIncentive: 0,
    mYearIncentive: 0,
  });

  const checkWhitelist = useCallback(async () => {
    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_SHAGA_BACKEND_API + "/isWhitelist",
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          const { json } = response.data;
          if (json) setAllData(json);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error decoding token:", error);
      navigate("/auth");
    }
  }, [navigate]);

  useEffect(() => {
    checkWhitelist();
  }, [checkWhitelist, user]);

  const handleChange = useCallback((name, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const onHover = useCallback((event) => {
    const {
      features,
      point: { x, y },
    } = event;
    const hoveredFeature = features?.[0];
    setHoverInfo(hoveredFeature ? { feature: hoveredFeature, x, y } : null);
  }, []);

  const dataLayer = useMemo(() => {
    if (allData) {
      const maxvalueLayer = Math.max(
        ...allData.features.map((f) => f.properties[filter])
      );
      maxvalue = maxvalueLayer;
      return generateDataLayer({
        filter,
        maxvalue: maxvalueLayer,
        data: allData,
      });
    }
    return null;
  }, [allData, filter]);

  const clusterLayer = useMemo(() => {
    if (!globbers || !showGlobbers) return null;
    return (
      <Source type="geojson" id="globbers" data={globbers}>
        <Layer {...heatmapStyle()} />
        <Layer {...circleStyle()} />
      </Source>
    );
  }, [showGlobbers, globbers]);

  const handlePolygon = (polygonCoords, data) => {
    const filteredGlobbers = filterPointsWithinPolygon(
      polygonCoords,
      data,
      false
    );
    const filteredNodes = filterPointsWithinPolygon(polygonCoords, NODES, true);

    setSelectedGlobbers(
      filteredGlobbers.reduce((acc, item) => acc + item[1], 0)
    );
    setSelectedNodes(filteredNodes.length);
  };

  const onCreate = useCallback(
    (e) => {
      handlePolygon(e.features[0].geometry.coordinates[0], rawData);
    },
    [rawData, globbers]
  );

  // const onCreate = (e, data) => {
  //   console.log(data);
  //   //handlePolygon(e.features[0].geometry.coordinates[0], data);
  // };

  const onUpdate = useCallback(
    (e) => {
      handlePolygon(e.features[0].geometry.coordinates[0], rawData);
    },
    [rawData, globbers]
  );

  const onDelete = useCallback(() => {
    setSelectedGlobbers(0);
    setSelectedNodes(0);
  }, []);

  const pins = useMemo(
    () =>
      NODES.map((item, index) => (
        <Marker
          key={`marker-${index}`}
          longitude={item[2] as number}
          latitude={item[1] as number}
          anchor="bottom"
          onClick={(e) => {
            e.originalEvent.stopPropagation();
            setMarkerInfo({
              city: item[0],
              latitude: item[1],
              longitude: item[2],
              img: item[3],
            });
          }}
        >
          <Pin />
        </Marker>
      )),
    []
  );

  return (
    <>
      <MapGL
        initialViewState={{ latitude: 40, longitude: -100, zoom: 2 }}
        onLoad={() => setMapLoaded(true)}
        mapStyle="mapbox://styles/mapbox/dark-v9"
        mapboxAccessToken={MAPBOX_TOKEN}
        interactiveLayerIds={["data", "circle-globbers"]}
        //@ts-ignore
        projection="globe"
        onMouseMove={onHover}
      >
        {clusterLayer}
        {allData && (
          <Source type="geojson" data={allData}>
            <Layer {...dataLayer} />
          </Source>
        )}
        {showNodes && pins}
        {markerInfo && (
          <MarketPopup markerInfo={markerInfo} setMarkerInfo={setMarkerInfo} />
        )}

        {rawData && rawData.length > 0 && (
          <DrawControl
            position="bottom-left"
            displayControlsDefault={false}
            controls={{ polygon: true, trash: true }}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        )}
        {hoverInfo && <Tooltip hoverInfo={hoverInfo} values={values} />}
      </MapGL>

      {mapLoaded && <ControlPanel values={values} onChange={handleChange} />}
      <SelectPanel
        filter={filter}
        onChangeFilter={setFilter}
        setShowGlobbers={setShowGlobbers}
        setShowNodes={setShowNodes}
        showNodes={showNodes}
        showGlobbers={showGlobbers}
        count={selectedGlobbers}
        nodeCount={selectedNodes}
        setShowCampaigns={setShowCampaigns}
      />
      <GradientPanel maxvalue={maxvalue} />

      {showCampaigns && (
        <Campaigns
          globbers={selectedGlobbers}
          nodes={selectedNodes}
          setOpenModal={setShowCampaigns}
        />
      )}
    </>
  );
};
