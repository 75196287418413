import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Map } from "./Pages/Map/Map";
import Auth from "./Pages/Auth";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Routes,
  BrowserRouter,
} from "react-router-dom";

// const root = ReactDOM.createRoot(document.getElementById("root"));
export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/auth" element={<Auth />} /> */}
        <Route path="/" element={<Map />} />
        <Route path="/market" element={<Map />} />
      </Routes>
    </BrowserRouter>
  );
}
