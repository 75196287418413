export const Campaigns = ({ globbers, nodes, setOpenModal }: any) => {
  return (
    <div className="absolute top-[10%] left-[20%] w-[60%] h-[70%]">
      <div className="w-full h-full relative flex flex-col">
        <div className="absolute z-10 top-0 left-0 w-full h-full  bg-black opacity-80 rounded-lg"></div>

        {/* header */}
        <div className="w-full h-[10%] px-4 flex justify-between items-center p-2 z-20 relative border rounded-lg shadow bg-gray-800 border-gray-700">
          <span className="text-xl font-bold text-white">Campaigns</span>
          <span
            className="text-xl font-bold text-red-500 cursor-pointer"
            onClick={(e) => setOpenModal(false)}
          >
            X
          </span>
        </div>
        {/* body */}
        <div className="w-full h-full flex p-2 gap-4 z-20 relative">
          {/* CARD */}
          <div className="max-w-[350px] max-h-[320px] cursor-pointer  border border-gray-200 rounded-lg shadow bg-gray-800 border-gray-700">
            <img
              className="rounded-t-lg"
              src="https://lookaside.fbsbx.com/elementpath/media/?media_id=1410980602993122&version=1711687636&transcode_extension=webp"
              alt=""
            />

            <div className="p-5">
              <h5 className="text-xl font-bold tracking-tight text-gray-900 text-white text-center">
                Campaign 1
              </h5>
            </div>
          </div>
          {/* // */}
          {/* CARD */}
          <div className="max-w-[350px] max-h-[320px] cursor-pointer bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <img
              className="rounded-t-lg h-[250px]"
              src="https://cdn1.epicgames.com/offer/c77e320376c14aa68121bffb108721c7/EGS_Sky9Games_AKnightsQuest_S3-1360x766-94882ecd4e4c56a13555a21feb04f774_2560x1440-d3e9618954e788d5607cce8d858a00a8"
              alt=""
            />

            <div className="p-5">
              <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white text-center">
                Campaign 2
              </h5>
            </div>
          </div>
          {/* // */}
        </div>
        {/* footer */}
        <div
          className="w-full  flex justify-between p-4 z-20 relative"
          //   style={{ backgroundColor: "rgb(174, 187, 209)" }}
        >
          <div className="flex flex-col text-white">
            <span>Selected Globbers: {globbers}</span>
            <span>Selected Nodes: {nodes}</span>
          </div>

          <button
            type="submit"
            className="w-[20%] text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            onClick={(e) => setOpenModal(false)}
          >
            Send Campaign
          </button>
        </div>
      </div>
    </div>
  );
};
