import * as React from "react";

function Slider({ label, value, min, max, onChange }) {
  return (
    <div>
      <label className="slider-label">
        {label}: {value}
      </label>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export default Slider;
