import * as React from "react";
import Slider from "./slider";
import Input from "./input";
import TierPanel from "./tier-panel";

function ControlPanel({ values, onChange }) {
  const handleChange = (name, value) => {
    const numericValue = parseInt(value, 10);
    onChange(name, numericValue);
  };

  const [show, setShow] = React.useState(true);

  const labelStyle = {
    width: "40px",
  };
  const inputStyle = {
    margin: "0px 5px 0px 2px",
    width: "30px",
  };
  // const tdlabelStyle = {
  //   width: "35%",
  // };

  return (
    <div className="control-panel">
      {show && (
        <div className="control-panel-row">
          <div className="control-panel-column">
            <Slider
              label="Hours Available"
              value={values.hoursAvailale}
              min={0}
              max={24}
              onChange={(e) => handleChange("hoursAvailale", e.target.value)}
            />
            <Slider
              label="Utilization Rate"
              min={0}
              max={100}
              value={values.utilizationRate}
              onChange={(e) => handleChange("utilizationRate", e.target.value)}
            />
            <Input
              label="Credit Unit Cost"
              value={values.creditUnitCost}
              onChange={(e) => handleChange("creditUnitCost", e.target.value)}
            />
            <Slider
              label="Clients per Node"
              min={1}
              max={12}
              value={values.clientPerNode}
              onChange={(e) => handleChange("clientPerNode", e.target.value)}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label style={{ width: "200px", textAlign: "right" }}>
              node tier distribution
            </label>
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
              <div className="tier-distribution-row">
                <input
                  style={inputStyle}
                  type="number"
                  min={0}
                  max={100}
                  value={values.sTierDistribution}
                  onChange={(e) =>
                    handleChange("sTierDistribution", e.target.value)
                  }
                />
                <label style={labelStyle}> S </label>
              </div>
              <div className="tier-distribution-row">
                <input
                  style={inputStyle}
                  type="number"
                  min={0}
                  max={100}
                  value={values.hTierDistribution}
                  onChange={(e) =>
                    handleChange("hTierDistribution", e.target.value)
                  }
                />
                <label style={labelStyle}> H </label>
              </div>
              <div className="tier-distribution-row">
                <input
                  style={inputStyle}
                  type="number"
                  min={0}
                  max={100}
                  value={values.mTierDistribution}
                  onChange={(e) =>
                    handleChange("mTierDistribution", e.target.value)
                  }
                />
                <label style={labelStyle}> M </label>
              </div>
            </div>
            {values.sTierDistribution +
              values.hTierDistribution +
              values.mTierDistribution !==
              100 && (
              <label
                style={{ color: "red", width: "200px", textAlign: "center" }}
              >
                Total distributions should be 100
              </label>
            )}
          </div>
        </div>
      )}
      {show && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TierPanel
            filter="S"
            pccost={values.sPcCost}
            setpccost={(value) => handleChange("sPcCost", value)}
            taxrate={values.sTaxRate}
            setTaxrate={(value) => handleChange("sTaxRate", value)}
            econsum={values.sEnergyConsumption}
            setEconsum={(value) => handleChange("sEnergyConsumption", value)}
            cphcost={values.sCreditPerHourCost}
            setCphcost={(value) => handleChange("sCreditPerHourCost", value)}
            yincentive={values.sYearIncentive}
            setYincentive={(value) => handleChange("sYearIncentive", value)}
          />
          <TierPanel
            filter="H"
            pccost={values.hPcCost}
            setpccost={(value) => handleChange("hPcCost", value)}
            taxrate={values.hTaxRate}
            setTaxrate={(value) => handleChange("hTaxRate", value)}
            econsum={values.hEnergyConsumption}
            setEconsum={(value) => handleChange("hEnergyConsumption", value)}
            cphcost={values.hCreditPerHourCost}
            setCphcost={(value) => handleChange("hCreditPerHourCost", value)}
            yincentive={values.hYearIncentive}
            setYincentive={(value) => handleChange("hYearIncentive", value)}
          />
          <TierPanel
            filter="M"
            pccost={values.mPcCost}
            setpccost={(value) => handleChange("mPcCost", value)}
            taxrate={values.mTaxRate}
            setTaxrate={(value) => handleChange("mTaxRate", value)}
            econsum={values.mEnergyConsumption}
            setEconsum={(value) => handleChange("mEnergyConsumption", value)}
            cphcost={values.mCreditPerHourCost}
            setCphcost={(value) => handleChange("mCreditPerHourCost", value)}
            yincentive={values.mYearIncentive}
            setYincentive={(value) => handleChange("mYearIncentive", value)}
          />
        </div>
      )}
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          borderRadius: "6px",
          border: "1px solid black",
        }}
        onClick={() => {
          console.log(show);
          setShow(!show);
        }}
      >
        {show ? "HIDE CONTROL PANEL" : "SHOW CONTROL PANEL"}
      </div>
    </div>
  );
}

export default React.memo(ControlPanel);
