import type { HeatmapLayer } from "react-map-gl";
import type { CircleLayer, FillLayer } from "react-map-gl";

export function heatmapStyle(): HeatmapLayer {
  const dataLayer: HeatmapLayer = {
    id: "heatmap-globbers",
    type: "heatmap",
    source: "globbers",
    maxzoom: 9,
    paint: {
      // Increase the heatmap weight based on frequency and property magnitude
      "heatmap-weight": [
        "interpolate",
        ["linear"],
        ["get", "count"],
        0,
        0,
        5,
        1,
      ],
      // Increase the heatmap color weight weight by zoom level
      // heatmap-intensity is a multiplier on top of heatmap-weight
      "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 0, 0, 9, 3],
      // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
      // Begin color ramp at 0-stop with a 0-transparancy color
      // to create a blur-like effect.
      "heatmap-color": [
        "interpolate",
        ["linear"],
        ["heatmap-density"],
        0,
        "rgba(255,255,255,0)",
        0.2,
        "rgb(247, 202, 89)",
        0.4,
        "rgb(245, 179, 12)",
        0.6,
        "rgb(245, 140, 12)",
        0.8,
        "rgb(245, 90, 12)",
        1,
        "rgb(245, 16, 12)",
      ],
      // Adjust the heatmap radius by zoom level
      "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 0, 2, 9, 20],
      // Transition from heatmap to circle layer by zoom level
      "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 7, 1, 9, 0],
    },
  };

  return dataLayer;
}

export function circleStyle(): CircleLayer {
  const dataLayer: CircleLayer = {
    id: "circle-globbers",
    type: "circle",
    source: "globbers",
    minzoom: 7,
    // "source-layer": "original",
    paint: {
      // Size circle radius by earthquake magnitude and zoom level
      "circle-radius": [
        "interpolate",
        ["linear"],
        ["zoom"],
        7,
        ["interpolate", ["linear"], ["get", "count"], 1, 1, 6, 4],
        16,
        ["interpolate", ["linear"], ["get", "count"], 1, 5, 6, 50],
      ],
      // Color circle by earthquake magnitude
      "circle-color": [
        "interpolate",
        ["linear"],
        ["get", "mag"],
        1,
        "rgba(255,255,255,0)",
        2,
        "rgb(247, 202, 89)",
        3,
        "rgb(245, 179, 12)",
        4,
        "rgb(245, 140, 12)",
        5,
        "rgb(245, 90, 12)",
        6,
        "rgb(245, 16, 12)",
      ],
      "circle-stroke-color": "yellow",
      "circle-stroke-width": 1,
      // Transition from heatmap to circle layer by zoom level
      "circle-opacity": ["interpolate", ["linear"], ["zoom"], 7, 0, 8, 1],
    },
  };

  return dataLayer;
}

// export function circleStyleNodes(): CircleLayer {
//   const dataLayer: CircleLayer = {
//     id: "circle-nodes",
//     type: "circle",
//     source: "nodes",
//     minzoom: 7,
//     // "source-layer": "original",
//     paint: {
//       // Size circle radius by earthquake magnitude and zoom level
//       "circle-radius": [
//         "interpolate",
//         ["linear"],
//         ["zoom"],
//         7,
//         ["interpolate", ["linear"], ["get", "count"], 1, 1, 6, 4],
//         16,
//         ["interpolate", ["linear"], ["get", "count"], 1, 5, 6, 50],
//       ],
//       // Color circle by earthquake magnitude
//       "circle-color": [
//         "interpolate",
//         ["linear"],
//         ["get", "mag"],
//         1,
//         "rgba(255,255,255,0)",
//         2,
//         "rgb(247, 202, 89)",
//         3,
//         "rgb(245, 179, 12)",
//         4,
//         "rgb(245, 140, 12)",
//         5,
//         "rgb(245, 90, 12)",
//         6,
//         "rgb(245, 16, 12)",
//       ],
//       "circle-stroke-color": "yellow",
//       "circle-stroke-width": 1,
//       // Transition from heatmap to circle layer by zoom level
//       "circle-opacity": ["interpolate", ["linear"], ["zoom"], 7, 0, 8, 1],
//     },
//   };

//   return dataLayer;
// }
