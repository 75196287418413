export const calculateYield = (values, AvgElectricityKWh, tier) => {
  const config = {
    S: {
      pcCost: values.sPcCost,
      taxRate: values.sTaxRate,
      energyConsumption: values.sEnergyConsumption,
      creditPerHourCost: values.sCreditPerHourCost,
      yearIncentive: values.sYearIncentive,
    },
    H: {
      pcCost: values.hPcCost,
      taxRate: values.hTaxRate,
      energyConsumption: values.hEnergyConsumption,
      creditPerHourCost: values.hCreditPerHourCost,
      yearIncentive: values.hYearIncentive,
    },
    M: {
      pcCost: values.mPcCost,
      taxRate: values.mTaxRate,
      energyConsumption: values.mEnergyConsumption,
      creditPerHourCost: values.mCreditPerHourCost,
      yearIncentive: values.mYearIncentive,
    },
  };
  const {
    pcCost,
    taxRate,
    energyConsumption,
    creditPerHourCost,
    yearIncentive,
  } = config[tier];
  const dailyEarnings =
    ((values.hoursAvailale * values.utilizationRate) / 100) *
    creditPerHourCost *
    values.creditUnitCost;
  const dailyExpenses =
    ((values.hoursAvailale * values.utilizationRate) / 100) *
    energyConsumption *
    AvgElectricityKWh;
  const yearEarnings =
    (dailyEarnings - dailyExpenses + yearIncentive / 365) *
    365 *
    (1 - taxRate / 100);
  return yearEarnings;
  // return (yearEarnings / pcCost) * 100;
};

export const calculateShagaReward = (values, mobileYoungGamer, scenario) => {
  const {
    clientPerNode,
    sCreditPerHourCost,
    hCreditPerHourCost,
    mCreditPerHourCost,
    sYearIncentive,
    hYearIncentive,
    mYearIncentive,
    sTaxRate,
    hTaxRate,
    mTaxRate,
    sTierDistribution,
    hTierDistribution,
    mTierDistribution,
  } = values;
  const temp =
    (values.hoursAvailale * values.utilizationRate * values.creditUnitCost) /
    100;
  // console.log(
  //   values.hoursAvailale,
  //   values.utilizationRate,
  //   values.creditUnitCost
  // );
  const yearEarnings =
    ((mobileYoungGamer * scenario) / clientPerNode / 100) *
    (((temp * sCreditPerHourCost * 365 + sYearIncentive) *
      (sTaxRate / 100) *
      sTierDistribution +
      (temp * hCreditPerHourCost * 365 + hYearIncentive) *
        (hTaxRate / 100) *
        hTierDistribution +
      (temp * mCreditPerHourCost * 365 + mYearIncentive) *
        (mTaxRate / 100) *
        mTierDistribution) /
      100);
  return yearEarnings;
};

export const numberWithApostrophes = (number) => {
  return number?.toLocaleString().replace(/,/g, "'"); // Replace commas with apostrophes
};

export function isPointInPolygon(point, polygon) {
  let inside = false;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    if (
      polygon[i][1] > point[1] !== polygon[j][1] > point[1] &&
      point[0] <
        ((polygon[j][0] - polygon[i][0]) * (point[1] - polygon[i][1])) /
          (polygon[j][1] - polygon[i][1]) +
          polygon[i][0]
    ) {
      inside = !inside;
    }
  }

  return inside;
}

export function filterPointsWithinPolygon(polygon, data, isNode) {
  if (isNode) {
    return data?.filter((point) =>
      isPointInPolygon([point[2], point[1]], polygon)
    );
  }

  return data?.filter((point) =>
    isPointInPolygon([point[3], point[2]], polygon)
  );
}
