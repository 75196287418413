import * as React from "react";

function TierPanel({
  filter,
  pccost,
  setpccost,
  taxrate,
  setTaxrate,
  econsum,
  setEconsum,
  cphcost,
  setCphcost,
  yincentive,
  setYincentive,
}) {
  const labelStyle = {
    // fontSize: '10px',
    width: "63%",
  };
  const inputStyle = {
    margin: "0px",
    width: "30%",
  };
  return (
    <div className="tier-panel">
      <label style={labelStyle}>
        {" "}
        Tier <b>{filter}</b> PC Cost:{" "}
      </label>
      <input
        style={inputStyle}
        type="number"
        min={0}
        value={pccost}
        onChange={(e) => setpccost(e.target.value)}
      />
      <label style={labelStyle}> Shaga Tax %: </label>
      <input
        style={inputStyle}
        type="number"
        min={0}
        max={100}
        value={taxrate}
        onChange={(e) => setTaxrate(e.target.value)}
      />
      <label style={labelStyle}> Energy Consumption: </label>
      <input
        style={inputStyle}
        type="number"
        min={0}
        value={econsum}
        onChange={(e) => setEconsum(e.target.value)}
      />
      <label style={labelStyle}> Credits per Hour Cost: </label>
      <input
        style={inputStyle}
        type="number"
        min={0}
        value={cphcost}
        onChange={(e) => setCphcost(e.target.value)}
      />
      <label style={labelStyle}> Yearly Incentives: </label>
      <input
        style={inputStyle}
        type="number"
        min={0}
        value={yincentive}
        onChange={(e) => setYincentive(e.target.value)}
        max={pccost}
      />
    </div>
  );
}

export default TierPanel;
