import * as React from "react";

function Input({ label, value, onChange }) {
  return (
    <div>
      <label className="slider-label">
        {label}: {value}
      </label>
      <input
        type="number"
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export default Input;
