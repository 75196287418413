export const NODES = [
  [
    "Tokyo–Yokohama",
    35.5147457,
    139.4839981,
    "https://masterbundles.com/wp-content/uploads/2023/04/1-what-is-the-best-desktop-computer-for-graphic-design-155.png",
  ],
  [
    "Manila",
    14.5906216,
    120.9799696,
    "https://masterbundles.com/wp-content/uploads/2023/04/1-what-is-the-best-desktop-computer-for-graphic-design-155.png",
  ],
  [
    "Jakarta",
    -6.1753942,
    106.827183,
    "https://masterbundles.com/wp-content/uploads/2023/04/1-what-is-the-best-desktop-computer-for-graphic-design-155.png",
  ],
  [
    "Delhi",
    28.6517178,
    77.2219388,
    "https://www.digitalstorm.com/img/desktop-gaming-pcs.webp",
  ],
  [
    "Seoul-Incheon",
    37.5056926,
    126.7358775,
    "https://www.digitalstorm.com/img/desktop-gaming-pcs.webp",
  ],
  [
    "Mumbai",
    18.9387711,
    72.8353355,
    "https://www.digitalstorm.com/img/desktop-gaming-pcs.webp",
  ],
  [
    "Mexico City",
    19.4326296,
    -99.1331785,
    "https://www.digitalstorm.com/img/desktop-gaming-pcs.webp",
  ],
  [
    "Guangzhou–Foshan",
    23.1592862,
    113.4566381,
    "https://www.digitalstorm.com/img/desktop-gaming-pcs.webp",
  ],
  [
    "New York",
    40.7127281,
    -74.0060152,
    "https://www.digitalstorm.com/img/desktop-gaming-pcs.webp",
  ],
  [
    "Dhaka",
    23.7593572,
    90.3788136,
    "https://spy.com/wp-content/uploads/2022/10/MSI-Aegis-Ti5-Gaming-Desktop-.png?w=764",
  ],
  [
    "Osaka–Kobe–Kyoto",
    34.8038695,
    135.5156628,
    "https://spy.com/wp-content/uploads/2022/10/MSI-Aegis-Ti5-Gaming-Desktop-.png?w=764",
  ],
  [
    "Moscow",
    55.7504461,
    37.6174943,
    "https://spy.com/wp-content/uploads/2022/10/MSI-Aegis-Ti5-Gaming-Desktop-.png?w=764",
  ],
  [
    "Cairo",
    30.048819,
    31.243666,
    "https://spy.com/wp-content/uploads/2022/10/MSI-Aegis-Ti5-Gaming-Desktop-.png?w=764",
  ],
  [
    "Beijing",
    39.906217,
    116.3912757,
    "https://spy.com/wp-content/uploads/2022/10/MSI-Aegis-Ti5-Gaming-Desktop-.png?w=764",
  ],
  [
    "Karachi",
    24.8667795,
    67.0311286,
    "https://spy.com/wp-content/uploads/2022/10/MSI-Aegis-Ti5-Gaming-Desktop-.png?w=764",
  ],
  [
    "Buenos Aires",
    -34.6075682,
    -58.4370894,
    "https://spy.com/wp-content/uploads/2022/10/MSI-Aegis-Ti5-Gaming-Desktop-.png?w=764",
  ],
  [
    "Los Angeles",
    34.0536909,
    -118.2427666,
    "https://spy.com/wp-content/uploads/2022/10/MSI-Aegis-Ti5-Gaming-Desktop-.png?w=764",
  ],
  [
    "Bangkok",
    13.7542529,
    100.493087,
    "https://spy.com/wp-content/uploads/2022/10/MSI-Aegis-Ti5-Gaming-Desktop-.png?w=764",
  ],
  [
    "São Paulo",
    -23.5506507,
    -46.6333824,
    "https://spy.com/wp-content/uploads/2022/10/MSI-Aegis-Ti5-Gaming-Desktop-.png?w=764",
  ],
  [
    "Lagos",
    6.4550575,
    3.3941795,
    "https://i.pinimg.com/736x/0b/f9/ac/0bf9acec548f710a9b168803b0e1de13.jpg",
  ],
  [
    "Kolkata",
    22.5677459,
    88.3476023,
    "https://i.pinimg.com/736x/0b/f9/ac/0bf9acec548f710a9b168803b0e1de13.jpg",
  ],
  [
    "Shenzhen",
    22.555454,
    114.0543297,
    "https://i.pinimg.com/736x/0b/f9/ac/0bf9acec548f710a9b168803b0e1de13.jpg",
  ],
  [
    "Istanbul",
    41.0096334,
    28.9651646,
    "https://i.pinimg.com/736x/0b/f9/ac/0bf9acec548f710a9b168803b0e1de13.jpg",
  ],
  [
    "Tehran",
    35.7006177,
    51.4013785,
    "https://i.pinimg.com/736x/0b/f9/ac/0bf9acec548f710a9b168803b0e1de13.jpg",
  ],
  [
    "Tianjin",
    39.1235635,
    117.1980785,
    "https://i.pinimg.com/736x/0b/f9/ac/0bf9acec548f710a9b168803b0e1de13.jpg",
  ],
  [
    "Shanghai",
    31.2252985,
    121.4890497,
    "https://i.pinimg.com/736x/0b/f9/ac/0bf9acec548f710a9b168803b0e1de13.jpg",
  ],
  [
    "Rio de Janeiro",
    -22.9110137,
    -43.2093727,
    "https://i.pinimg.com/736x/0b/f9/ac/0bf9acec548f710a9b168803b0e1de13.jpg",
  ],
  [
    "Lima",
    -12.0621065,
    -77.0365256,
    "https://i.pinimg.com/736x/0b/f9/ac/0bf9acec548f710a9b168803b0e1de13.jpg",
  ],
  [
    "Lahore",
    31.5656079,
    74.3141775,
    "https://i.pinimg.com/736x/0b/f9/ac/0bf9acec548f710a9b168803b0e1de13.jpg",
  ],
  [
    "Ho Chi Minh City",
    10.6497452,
    106.7619794,
    "https://i.pinimg.com/736x/0b/f9/ac/0bf9acec548f710a9b168803b0e1de13.jpg",
  ],
  [
    "London",
    51.5073219,
    -0.1276474,
    "https://www.intel.co.uk/content/dam/www/public/us/en/images/video-thumbnails/15s-vid-intel-pro-rpls-14thg-ag-na-hqprim-na-us-eng-16x9-video-thumbnail.png.rendition.intel.web.1920.1080.png",
  ],
  [
    "Bangalore",
    12.9791198,
    77.5912997,
    "https://www.intel.co.uk/content/dam/www/public/us/en/images/video-thumbnails/15s-vid-intel-pro-rpls-14thg-ag-na-hqprim-na-us-eng-16x9-video-thumbnail.png.rendition.intel.web.1920.1080.png",
  ],
  [
    "Paris",
    48.8566969,
    2.3514616,
    "https://www.intel.co.uk/content/dam/www/public/us/en/images/video-thumbnails/15s-vid-intel-pro-rpls-14thg-ag-na-hqprim-na-us-eng-16x9-video-thumbnail.png.rendition.intel.web.1920.1080.png",
  ],
  [
    "Chengdu",
    30.6624205,
    104.0633219,
    "https://www.intel.co.uk/content/dam/www/public/us/en/images/video-thumbnails/15s-vid-intel-pro-rpls-14thg-ag-na-hqprim-na-us-eng-16x9-video-thumbnail.png.rendition.intel.web.1920.1080.png",
  ],
  [
    "Nagoya (Chūkyō)",
    35.1387199,
    136.9665068,
    "https://www.intel.co.uk/content/dam/www/public/us/en/images/video-thumbnails/15s-vid-intel-pro-rpls-14thg-ag-na-hqprim-na-us-eng-16x9-video-thumbnail.png.rendition.intel.web.1920.1080.png",
  ],
  [
    "Chennai",
    13.0801721,
    80.2838331,
    "https://www.intel.co.uk/content/dam/www/public/us/en/images/video-thumbnails/15s-vid-intel-pro-rpls-14thg-ag-na-hqprim-na-us-eng-16x9-video-thumbnail.png.rendition.intel.web.1920.1080.png",
  ],
  [
    "Hyderabad",
    25.3801017,
    68.3750376,
    "https://www.intel.co.uk/content/dam/www/public/us/en/images/video-thumbnails/15s-vid-intel-pro-rpls-14thg-ag-na-hqprim-na-us-eng-16x9-video-thumbnail.png.rendition.intel.web.1920.1080.png",
  ],
  [
    "Bogotá",
    4.59808,
    -74.0760439,
    "https://www.intel.co.uk/content/dam/www/public/us/en/images/video-thumbnails/15s-vid-intel-pro-rpls-14thg-ag-na-hqprim-na-us-eng-16x9-video-thumbnail.png.rendition.intel.web.1920.1080.png",
  ],
  [
    "Taipei-Taoyuan",
    24.9929995,
    121.3010003,
    "https://www.intel.co.uk/content/dam/www/public/us/en/images/video-thumbnails/15s-vid-intel-pro-rpls-14thg-ag-na-hqprim-na-us-eng-16x9-video-thumbnail.png.rendition.intel.web.1920.1080.png",
  ],
  [
    "Kinshasa",
    -4.3217055,
    15.3125974,
    "https://www.intel.co.uk/content/dam/www/public/us/en/images/video-thumbnails/15s-vid-intel-pro-rpls-14thg-ag-na-hqprim-na-us-eng-16x9-video-thumbnail.png.rendition.intel.web.1920.1080.png",
  ],
  [
    "Chicago",
    41.8755616,
    -87.6244212,
    "https://www.intel.co.uk/content/dam/www/public/us/en/images/video-thumbnails/15s-vid-intel-pro-rpls-14thg-ag-na-hqprim-na-us-eng-16x9-video-thumbnail.png.rendition.intel.web.1920.1080.png",
  ],
  [
    "Wuhan",
    30.5951051,
    114.2999353,
    "https://www.intel.co.uk/content/dam/www/public/us/en/images/video-thumbnails/15s-vid-intel-pro-rpls-14thg-ag-na-hqprim-na-us-eng-16x9-video-thumbnail.png.rendition.intel.web.1920.1080.png",
  ],
  [
    "Onitsha",
    6.133234,
    6.792318,
    "https://assets-prd.ignimgs.com/2022/02/02/gamingpc-1643836539734.jpg",
  ],
  [
    "Kuala Lumpur",
    3.1516964,
    101.6942371,
    "https://assets-prd.ignimgs.com/2022/02/02/gamingpc-1643836539734.jpg",
  ],
  [
    "Dongguan",
    23.0444712,
    113.7465512,
    "https://assets-prd.ignimgs.com/2022/02/02/gamingpc-1643836539734.jpg",
  ],
  [
    "Hanoi",
    21.0294498,
    105.8544441,
    "https://assets-prd.ignimgs.com/2022/02/02/gamingpc-1643836539734.jpg",
  ],
  [
    "Ahmedabad",
    23.0216238,
    72.5797068,
    "https://assets-prd.ignimgs.com/2022/02/02/gamingpc-1643836539734.jpg",
  ],
  [
    "Washington, D.C.-Baltimore",
    39.2908816,
    -76.610759,
    "https://assets-prd.ignimgs.com/2022/02/02/gamingpc-1643836539734.jpg",
  ],
  [
    "Luanda",
    -8.8272699,
    13.2439512,
    "https://assets-prd.ignimgs.com/2022/02/02/gamingpc-1643836539734.jpg",
  ],
  [
    "Baghdad",
    33.3024309,
    44.3787992,
    "https://assets-prd.ignimgs.com/2022/02/02/gamingpc-1643836539734.jpg",
  ],
  [
    "Xi'an–Xianyang",
    34.3075655,
    108.7234362,
    "https://assets-prd.ignimgs.com/2022/02/02/gamingpc-1643836539734.jpg",
  ],
  [
    "Hong Kong",
    22.2793278,
    114.1628131,
    "https://assets-prd.ignimgs.com/2022/02/02/gamingpc-1643836539734.jpg",
  ],
  [
    "Johannesburg–East Rand",
    35.3732015,
    -117.6432874,
    "https://assets-prd.ignimgs.com/2022/02/02/gamingpc-1643836539734.jpg",
  ],
  [
    "Zhengzhou–Xingyang",
    34.7891274,
    113.3724566,
    "https://assets-prd.ignimgs.com/2022/02/02/gamingpc-1643836539734.jpg",
  ],
  [
    "Boston-Providence",
    41.7048225,
    -71.5483952,
    "https://assets-prd.ignimgs.com/2022/02/02/gamingpc-1643836539734.jpg",
  ],
  [
    "Shenyang",
    41.8041094,
    123.4276363,
    "https://assets-prd.ignimgs.com/2022/02/02/gamingpc-1643836539734.jpg",
  ],
  [
    "Hangzhou",
    30.2489634,
    120.2052342,
    "https://assets-prd.ignimgs.com/2022/02/02/gamingpc-1643836539734.jpg",
  ],
  [
    "Toronto–Hamilton",
    43.6706177,
    -79.3746817,
    "https://assets-prd.ignimgs.com/2022/02/02/gamingpc-1643836539734.jpg",
  ],
  [
    "Quanzhou",
    24.9038801,
    118.5851458,
    "https://assets-prd.ignimgs.com/2022/02/02/gamingpc-1643836539734.jpg",
  ],
  [
    "Dallas–Fort Worth",
    32.7379037,
    -97.2394281,
    "https://assets-prd.ignimgs.com/2022/02/02/gamingpc-1643836539734.jpg",
  ],
  [
    "Santiago",
    9.8694792,
    -83.7980749,
    "https://assets-prd.ignimgs.com/2022/02/02/gamingpc-1643836539734.jpg",
  ],
  [
    "Houston",
    29.7589382,
    -95.3676974,
    "https://assets-prd.ignimgs.com/2022/02/02/gamingpc-1643836539734.jpg",
  ],
  [
    "Surat",
    45.9383,
    3.2553,
    "https://5.imimg.com/data5/TP/QU/QF/SELLER-32712236/gaming-pc-500x500.jpeg",
  ],
  [
    "Madrid",
    40.4167047,
    -3.7035825,
    "https://5.imimg.com/data5/TP/QU/QF/SELLER-32712236/gaming-pc-500x500.jpeg",
  ],
  [
    "Nanjing",
    32.0609736,
    118.7916458,
    "https://5.imimg.com/data5/TP/QU/QF/SELLER-32712236/gaming-pc-500x500.jpeg",
  ],
  [
    "Pune",
    18.521428,
    73.8544541,
    "https://5.imimg.com/data5/TP/QU/QF/SELLER-32712236/gaming-pc-500x500.jpeg",
  ],
  [
    "Riyadh",
    24.6319692,
    46.7150648,
    "https://5.imimg.com/data5/TP/QU/QF/SELLER-32712236/gaming-pc-500x500.jpeg",
  ],
  [
    "Nairobi",
    -1.2832533,
    36.8172449,
    "https://5.imimg.com/data5/TP/QU/QF/SELLER-32712236/gaming-pc-500x500.jpeg",
  ],
  [
    "Qingdao–Jimo",
    36.4660932,
    120.6190022,
    "https://5.imimg.com/data5/TP/QU/QF/SELLER-32712236/gaming-pc-500x500.jpeg",
  ],
  [
    "Miami",
    25.7742658,
    -80.1936589,
    "https://5.imimg.com/data5/TP/QU/QF/SELLER-32712236/gaming-pc-500x500.jpeg",
  ],
  [
    "San Francisco-San Jose",
    8.3512768,
    -81.0390519,
    "https://5.imimg.com/data5/TP/QU/QF/SELLER-32712236/gaming-pc-500x500.jpeg",
  ],
  [
    "Philadelphia",
    39.9527237,
    -75.1635262,
    "https://5.imimg.com/data5/TP/QU/QF/SELLER-32712236/gaming-pc-500x500.jpeg",
  ],
  [
    "Khartoum",
    15.593325,
    32.53565,
    "https://5.imimg.com/data5/TP/QU/QF/SELLER-32712236/gaming-pc-500x500.jpeg",
  ],
  [
    "Amman",
    31.9515694,
    35.9239625,
    "https://5.imimg.com/data5/TP/QU/QF/SELLER-32712236/gaming-pc-500x500.jpeg",
  ],
  [
    "Atlanta",
    33.7490987,
    -84.3901849,
    "https://5.imimg.com/data5/TP/QU/QF/SELLER-32712236/gaming-pc-500x500.jpeg",
  ],
  [
    "Singapore",
    1.340863,
    103.8303918,
    "https://5.imimg.com/data5/TP/QU/QF/SELLER-32712236/gaming-pc-500x500.jpeg",
  ],
  [
    "Chongqing",
    29.5585712,
    106.5492822,
    "https://5.imimg.com/data5/TP/QU/QF/SELLER-32712236/gaming-pc-500x500.jpeg",
  ],
  [
    "Yangon",
    16.7967129,
    96.1609916,
    "https://5.imimg.com/data5/TP/QU/QF/SELLER-32712236/gaming-pc-500x500.jpeg",
  ],
  [
    "Suzhou",
    31.3016935,
    120.5810725,
    "https://5.imimg.com/data5/TP/QU/QF/SELLER-32712236/gaming-pc-500x500.jpeg",
  ],
  [
    "Milan",
    45.4668,
    9.1905,
    "https://5.imimg.com/data5/TP/QU/QF/SELLER-32712236/gaming-pc-500x500.jpeg",
  ],
  [
    "Aleppo",
    36.19924,
    37.1637253,
    "https://5.imimg.com/data5/TP/QU/QF/SELLER-32712236/gaming-pc-500x500.jpeg",
  ],
  [
    "Saint Petersburg",
    59.938732,
    30.316229,
    "https://5.imimg.com/data5/TP/QU/QF/SELLER-32712236/gaming-pc-500x500.jpeg",
  ],
  [
    "Dar es Salaam",
    -6.8160837,
    39.2803583,
    "https://5.imimg.com/data5/TP/QU/QF/SELLER-32712236/gaming-pc-500x500.jpeg",
  ],
  [
    "Bandung",
    -6.9344694,
    107.6049539,
    "https://ae01.alicdn.com/kf/Sb9aebc209e2b4ef4b21371738d5cf31dM/Wholesale-assembled-best-desktop-computer-for-home-use-intel-core-A8-7680-CPU-16G-RAM-256G.jpg",
  ],
  [
    "Guadalajara",
    20.6720375,
    -103.3383962,
    "https://ae01.alicdn.com/kf/Sb9aebc209e2b4ef4b21371738d5cf31dM/Wholesale-assembled-best-desktop-computer-for-home-use-intel-core-A8-7680-CPU-16G-RAM-256G.jpg",
  ],
  [
    "Barcelona",
    41.3828939,
    2.1774322,
    "https://ae01.alicdn.com/kf/Sb9aebc209e2b4ef4b21371738d5cf31dM/Wholesale-assembled-best-desktop-computer-for-home-use-intel-core-A8-7680-CPU-16G-RAM-256G.jpg",
  ],
  ["Belo Horizonte", -19.9227318, -43.9450948],
  [
    "Alexandria",
    31.199004,
    29.894378,
    "https://ae01.alicdn.com/kf/Sb9aebc209e2b4ef4b21371738d5cf31dM/Wholesale-assembled-best-desktop-computer-for-home-use-intel-core-A8-7680-CPU-16G-RAM-256G.jpg",
  ],
  [
    "Kuwait City",
    29.3797091,
    47.9735629,
    "https://ae01.alicdn.com/kf/Sb9aebc209e2b4ef4b21371738d5cf31dM/Wholesale-assembled-best-desktop-computer-for-home-use-intel-core-A8-7680-CPU-16G-RAM-256G.jpg",
  ],
  [
    "Harbin",
    45.7656666,
    126.6160584,
    "https://ae01.alicdn.com/kf/Sb9aebc209e2b4ef4b21371738d5cf31dM/Wholesale-assembled-best-desktop-computer-for-home-use-intel-core-A8-7680-CPU-16G-RAM-256G.jpg",
  ],
  [
    "Sydney",
    -33.8548157,
    151.2164539,
    "https://ae01.alicdn.com/kf/Sb9aebc209e2b4ef4b21371738d5cf31dM/Wholesale-assembled-best-desktop-computer-for-home-use-intel-core-A8-7680-CPU-16G-RAM-256G.jpg",
  ],
  [
    "Abidjan",
    5.320357,
    -4.016107,
    "https://ae01.alicdn.com/kf/Sb9aebc209e2b4ef4b21371738d5cf31dM/Wholesale-assembled-best-desktop-computer-for-home-use-intel-core-A8-7680-CPU-16G-RAM-256G.jpg",
  ],
  [
    "Casablanca",
    33.5950627,
    -7.6187768,
    "https://ae01.alicdn.com/kf/Sb9aebc209e2b4ef4b21371738d5cf31dM/Wholesale-assembled-best-desktop-computer-for-home-use-intel-core-A8-7680-CPU-16G-RAM-256G.jpg",
  ],
  [
    "Melbourne",
    -37.8142176,
    144.9631608,
    "https://ae01.alicdn.com/kf/Sb9aebc209e2b4ef4b21371738d5cf31dM/Wholesale-assembled-best-desktop-computer-for-home-use-intel-core-A8-7680-CPU-16G-RAM-256G.jpg",
  ],
  [
    "Phoenix",
    33.4484367,
    -112.0741417,
    "https://ae01.alicdn.com/kf/Sb9aebc209e2b4ef4b21371738d5cf31dM/Wholesale-assembled-best-desktop-computer-for-home-use-intel-core-A8-7680-CPU-16G-RAM-256G.jpg",
  ],
  [
    "Colombo",
    6.9349969,
    79.8538463,
    "https://ae01.alicdn.com/kf/Sb9aebc209e2b4ef4b21371738d5cf31dM/Wholesale-assembled-best-desktop-computer-for-home-use-intel-core-A8-7680-CPU-16G-RAM-256G.jpg",
  ],
  [
    "Monterrey",
    25.6802019,
    -100.3152586,
    "https://ae01.alicdn.com/kf/Sb9aebc209e2b4ef4b21371738d5cf31dM/Wholesale-assembled-best-desktop-computer-for-home-use-intel-core-A8-7680-CPU-16G-RAM-256G.jpg",
  ],
  [
    "Surabaya",
    -7.2459717,
    112.7378266,
    "https://ae01.alicdn.com/kf/Sb9aebc209e2b4ef4b21371738d5cf31dM/Wholesale-assembled-best-desktop-computer-for-home-use-intel-core-A8-7680-CPU-16G-RAM-256G.jpg",
  ],
  [
    "Ankara",
    39.9207774,
    32.854067,
    "https://ae01.alicdn.com/kf/Sb9aebc209e2b4ef4b21371738d5cf31dM/Wholesale-assembled-best-desktop-computer-for-home-use-intel-core-A8-7680-CPU-16G-RAM-256G.jpg",
  ],
];
