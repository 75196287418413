import type { FillLayer } from "react-map-gl";

interface MapStyleParams {
  filter: string;
  maxvalue: number;
  data: object;
}

export function generateDataLayer(params: MapStyleParams): FillLayer {
  const { filter, maxvalue } = params;

  const dataLayer: FillLayer = {
    id: "data",
    type: "fill",
    paint: {
      "fill-color": {
        property: filter, // Use the filter property to determine color
        stops: [
          [0, "#ffffff"], // Define color stops based on property values
          [maxvalue, "#ff0000"], // Adjust color stops as needed
        ],
      },
      "fill-opacity": 0.8,
    },
  };

  return dataLayer;
}
