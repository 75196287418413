import {
  calculateShagaReward,
  calculateYield,
  numberWithApostrophes,
} from "../../utils/helpers";

export const Tooltip = ({ values, hoverInfo }: any) => {
  return (
    <div className="tooltip" style={{ left: hoverInfo.x, top: hoverInfo.y }}>
      {hoverInfo?.feature?.layer?.id === "circle-globbers" ? (
        <>
          <div>City: {hoverInfo.feature.properties.city}</div>
          <div style={{ color: "#2be3e0" }}>
            Population:{" "}
            {numberWithApostrophes(hoverInfo.feature.properties.count)}
          </div>
        </>
      ) : (
        <>
          <div>Country: {hoverInfo.feature.properties.Country}</div>
          <div style={{ color: "#2be3e0" }}>
            <div>
              Population2023:{" "}
              {numberWithApostrophes(
                hoverInfo.feature.properties.Population2023
              )}
            </div>
            <div>
              Young Metropolitan Gamers:{" "}
              {numberWithApostrophes(
                hoverInfo.feature.properties["Mobile Young Gamers"]
              )}
            </div>
            <div>
              Broadband (Mbps):{" "}
              {numberWithApostrophes(
                hoverInfo.feature.properties["Broadband (Mbps)"]
              )}
            </div>
            <div>
              Avg Electricity $/kWh:{" "}
              {numberWithApostrophes(
                hoverInfo.feature.properties["Avg Electricity $/kWh"]
              )}
            </div>
          </div>
          <div style={{ color: "#e0e01f" }}>
            <div>
              <b>Annual Node Profits :</b>
            </div>
            <div>
              Tier S $:{" "}
              {numberWithApostrophes(
                Math.round(
                  calculateYield(
                    values,
                    hoverInfo.feature.properties["Avg Electricity $/kWh"],
                    "S"
                  )
                )
              )}
            </div>
            <div>
              Tier H $:{" "}
              {numberWithApostrophes(
                Math.round(
                  calculateYield(
                    values,
                    hoverInfo.feature.properties["Avg Electricity $/kWh"],
                    "H"
                  )
                )
              )}
            </div>
            <div>
              Tier M $:{" "}
              {numberWithApostrophes(
                Math.round(
                  calculateYield(
                    values,
                    hoverInfo.feature.properties["Avg Electricity $/kWh"],
                    "M"
                  )
                )
              )}
            </div>
          </div>
          <div style={{ color: "#3aa832" }}>
            <div>
              <b>Total Shaga Profits :</b>
            </div>
            <div>
              1% Scenario $:{" "}
              {numberWithApostrophes(
                Math.round(
                  calculateShagaReward(
                    values,
                    hoverInfo.feature.properties["Mobile Young Gamers"],
                    "1"
                  )
                )
              )}
            </div>
            <div>
              15% Scenario $:{" "}
              {numberWithApostrophes(
                Math.round(
                  calculateShagaReward(
                    values,
                    hoverInfo.feature.properties["Mobile Young Gamers"],
                    "15"
                  )
                )
              )}
            </div>
            <div>
              50% Scenario $:{" "}
              {numberWithApostrophes(
                Math.round(
                  calculateShagaReward(
                    values,
                    hoverInfo.feature.properties["Mobile Young Gamers"],
                    "50"
                  )
                )
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
